<template>
  <div class="market" style="min-height: 99%; background: #fff">
    <a-card
      title="应用包审核"
      :headStyle="{ fontWeight: 'bold' }"
      style="min-height: 400px"
    >
      <!-- 标题右侧 -->
      <div slot="extra">
        <div class="right_option">
          <a-input-search
            placeholder="请输入应用包名称"
            enter-button
            allow-clear
            @search="onSearch"
          ></a-input-search>
          <a-select
            style="width: 200px; margin-left: 15px"
            defaultValue=""
            allow-clear
            @change="selectChange"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="UNEXAMINED">待审核</a-select-option>
            <a-select-option value="APPROVED">审核通过</a-select-option>
            <a-select-option value="DISAPPROVED">不通过</a-select-option>
            <a-select-option value="WITHDRAW">已撤销</a-select-option>
          </a-select>
        </div>
      </div>
      <!-- 内容 -->
      <div>
        <a-table
          :columns="columns"
          :data-source="data"
          @change="tableChange"
          :pagination="pagination"
        >
          <!-- 应用包 -->
          <div slot="name" slot-scope="text, record" class="appPage">
            <div>
              <img
                :src="record.packagePictures ? record.packagePictures[0] : ''"
                alt=""
              />
            </div>
            <div class="appName">
              <div>{{ record.packageName }}</div>
              <div>{{ record.packageDescription }}</div>
            </div>
          </div>
          <!-- 审核时间 -->
          <div slot="examineTime" slot-scope="text">
            {{ text || '---' }}
          </div>
          <!-- 状态 -->
          <span slot="examinationStatus" slot-scope="text">
            <div
              :style="{
                background:
                  text === 'APPROVED'
                    ? '#00A870'
                    : text === 'DISAPPROVED'
                    ? '#ED7B2F'
                    : text === 'UNEXAMINED'
                    ? '#E7E7E7'
                    : text === 'WITHDRAW'
                    ? '#E7E7E7'
                    : '',
                borderRadius: '20px',
                height: '30px',
                color:
                  text === 'UNEXAMINED' || text === 'WITHDRAW'
                    ? '#000'
                    : '#fff',
                height: '20px',
                width: '70px',
                textAlign: 'center',
              }"
            >
              {{
                text === 'APPROVED'
                  ? '审核通过'
                  : text === 'DISAPPROVED'
                  ? '不通过'
                  : text === 'UNEXAMINED'
                  ? '待审核'
                  : text === 'WITHDRAW'
                  ? '已撤销'
                  : '',
              }}
            </div>
          </span>
          <a slot="operation" slot-scope="text, record" @click="detail(record)">
            查看详情
          </a>
        </a-table>
        <application-detail
          v-if="visible"
          :visible.sync="visible"
          :type="type"
          :record="record"
          @close="close"
        ></application-detail>
      </div>
    </a-card>
  </div>
</template>

<script>
import applicationDetail from './modal/applicationDetail.vue'

const columns = [
  {
    title: '应用包id',
    dataIndex: 'packageId',
    key: 'packageId',
    width: '250px',
    scopedSlots: { customRender: 'packageId' },
  },
  {
    title: '应用包及名称备注',
    dataIndex: 'name',
    key: 'name',
    width: '30%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '审核状态',
    dataIndex: 'examinationStatus',
    key: 'examinationStatus',
    scopedSlots: { customRender: 'examinationStatus' },
    align: 'left',
    filters: [
      {
        text: '审核通过',
        value: 'APPROVED',
      },
      {
        text: '不通过',
        value: 'DISAPPROVED',
      },
      {
        text: '待审核',
        value: 'UNEXAMINED',
      },
      {
        text: '已撤销',
        value: 'WITHDRAW',
      },
    ],
    onFilter: (value, record) => record.examinationStatus.indexOf(value) === 0,
  },
  {
    title: '应用数量',
    dataIndex: 'packageApplicationNum',
    key: 'packageApplicationNum',
    align: 'center',
    scopedSlots: { customRender: 'packageApplicationNum' },
    width: '110px',
    sorter: (a, b) => a.packageApplicationNum - b.packageApplicationNum,
  },
  {
    title: '申请时间',
    dataIndex: 'submitTime',
    key: 'submitTime',
    scopedSlots: { customRender: 'submitTime' },
    align: 'center',
    sorter: (a, b) => new Date(a.submitTime) - new Date(b.submitTime),
  },
  {
    title: '审核时间',
    dataIndex: 'examineTime',
    key: 'examineTime',
    scopedSlots: { customRender: 'examineTime' },
    align: 'center',
    sorter: (a, b) => new Date(a.examineTime) - new Date(b.examineTime),
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    scopedSlots: { customRender: 'operation' },
    width: '100px',
  },
]

export default {
  name: 'ApplicationMarket',
  components: { applicationDetail },
  data() {
    return {
      columns,
      data: [],
      pagination: {
        current: 1, // 默认当前页数
        pageSize: 10, // 默认当前页显示数据的大小
        total: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '15', '20'],
      },
      visible: false,
      type: '',
      record: {},
      selectedKey: '',
      searchValue: '',
    }
  },
  created() {
    this.init()
  },
  methods: {
    /* 初始化 */
    init() {
      this.getApplicationPageList({
        currentPage: this.pagination.current,
        pageSize: this.pagination.pageSize,
      })
    },
    /* 获取应用包列表 */
    getApplicationPageList(
      pageInfo,
      filterInfo = {},
      packageName,
      orderByInfo
    ) {
      let query = {
        page: pageInfo,
        filter: {
          packageType: 'COMMODITY',
          ...this.cleanEmpty(filterInfo),
        },
        startsWith: {
          packageName,
        },
        orderBy: orderByInfo,
      }
      this.$api.getAppPageList(query).then((res) => {
        // console.log(res, '应用包审核列表')
        if (res.code === 0) {
          this.data = res.data.content
          this.pagination.total = Number(res.data.totalElements)
        }
      })
    },

    selectChange(value) {
      this.selectedKey = value
      this.getApplicationPageList(
        { currentPage: 1, pageSize: 10 },
        { examinationStatus: value }
      )
    },

    detail(record) {
      this.type = record.examinationStatus
      this.record = record
      this.visible = true
    },
    onSearch(value) {
      this.searchValue = value
      this.getApplicationPageList(
        { currentPage: 1, pageSize: 10 },
        { examinationStatus: this.selectedKey },
        value
      )
    },
    close(value) {
      this.visible = false
      if (value) {
        this.init()
      }
    },
    tableChange(pagination) {
      // console.log(pagination)
      let { current, pageSize } = pagination
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      this.getApplicationPageList(
        { currentPage: current, pageSize },
        { examinationStatus: this.selectedKey },
        this.searchValue
      )
    },
    /* 清楚对象中的空值 */
    cleanEmpty(object) {
      return Object.keys(object)
        .filter((key) => {
          return (
            object[key] !== ' ' &&
            object[key] !== null &&
            object[key] !== undefined &&
            object[key] !== ''
          )
        })
        .reduce((prev, curr) => {
          return { ...prev, [curr]: object[curr] }
        }, {})
    },
  },
}
</script>

<style lang="less" scoped>
.right_option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  :first-child {
    width: 40px;
    margin-right: 10px;
  }
  :nth-child(2) {
    width: calc(100% - 50px);
  }
  .appName {
    display: flex;
    flex-direction: column;
    width: 100%;
    div {
      overflow: hidden;
      text-overflow: ellipsis; /* 超出部分省略号 */
      word-break: break-all; /* break-all(允许在单词内换行。) */
      display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
      -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
      -webkit-line-clamp: 1; /* 显示的行数 */
      max-height: 80rpx;
    }
    :first-child {
      width: 100%;
      font-weight: bold;
    }
  }
}
</style>
