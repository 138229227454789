var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"market",staticStyle:{"min-height":"99%","background":"#fff"}},[_c('a-card',{staticStyle:{"min-height":"400px"},attrs:{"title":"应用包审核","headStyle":{ fontWeight: 'bold' }}},[_c('div',{attrs:{"slot":"extra"},slot:"extra"},[_c('div',{staticClass:"right_option"},[_c('a-input-search',{attrs:{"placeholder":"请输入应用包名称","enter-button":"","allow-clear":""},on:{"search":_vm.onSearch}}),_c('a-select',{staticStyle:{"width":"200px","margin-left":"15px"},attrs:{"defaultValue":"","allow-clear":""},on:{"change":_vm.selectChange}},[_c('a-select-option',{attrs:{"value":""}},[_vm._v("全部")]),_c('a-select-option',{attrs:{"value":"UNEXAMINED"}},[_vm._v("待审核")]),_c('a-select-option',{attrs:{"value":"APPROVED"}},[_vm._v("审核通过")]),_c('a-select-option',{attrs:{"value":"DISAPPROVED"}},[_vm._v("不通过")]),_c('a-select-option',{attrs:{"value":"WITHDRAW"}},[_vm._v("已撤销")])],1)],1)]),_c('div',[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"pagination":_vm.pagination},on:{"change":_vm.tableChange},scopedSlots:_vm._u([{key:"name",fn:function(text, record){return _c('div',{staticClass:"appPage"},[_c('div',[_c('img',{attrs:{"src":record.packagePictures ? record.packagePictures[0] : '',"alt":""}})]),_c('div',{staticClass:"appName"},[_c('div',[_vm._v(_vm._s(record.packageName))]),_c('div',[_vm._v(_vm._s(record.packageDescription))])])])}},{key:"examineTime",fn:function(text){return _c('div',{},[_vm._v(" "+_vm._s(text || '---')+" ")])}},{key:"examinationStatus",fn:function(text){return _c('span',{},[_c('div',{style:({
              background:
                text === 'APPROVED'
                  ? '#00A870'
                  : text === 'DISAPPROVED'
                  ? '#ED7B2F'
                  : text === 'UNEXAMINED'
                  ? '#E7E7E7'
                  : text === 'WITHDRAW'
                  ? '#E7E7E7'
                  : '',
              borderRadius: '20px',
              height: '30px',
              color:
                text === 'UNEXAMINED' || text === 'WITHDRAW'
                  ? '#000'
                  : '#fff',
              height: '20px',
              width: '70px',
              textAlign: 'center',
            })},[_vm._v(" "+_vm._s(text === 'APPROVED' ? '审核通过' : text === 'DISAPPROVED' ? '不通过' : text === 'UNEXAMINED' ? '待审核' : text === 'WITHDRAW' ? '已撤销' : '')+" ")])])}},{key:"operation",fn:function(text, record){return _c('a',{on:{"click":function($event){return _vm.detail(record)}}},[_vm._v(" 查看详情 ")])}}])}),(_vm.visible)?_c('application-detail',{attrs:{"visible":_vm.visible,"type":_vm.type,"record":_vm.record},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.close}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }