<template>
  <a-modal
    title="详情"
    :visible="visible"
    @cancel="handleCancel"
    width="1000px"
  >
    <div slot="footer">
      <div>
        提交人：{{ record.submitter.split('#')[1] }} （{{
          record.submitterTenant.split('#')[1]
        }}）
      </div>
      <div class="footer-operation" v-if="type == 'UNEXAMINED'">
        <a-button @click="pass" type="primary" icon="check-circle"
          >通过</a-button
        >
        <a-button @click="no_pass" type="danger" icon="close-circle"
          >不通过</a-button
        >
      </div>
      <div class="footer-operation" v-if="type === 'APPROVED'">
        <a-icon type="check-circle"></a-icon> 审核通过
      </div>
      <div class="footer-operation" v-if="type === 'DISAPPROVED'">
        <a-icon type="close-circle"></a-icon> 审核不通过
      </div>
    </div>
    <div class="box">
      <div class="left">
        <div class="left-title">提交应用</div>
        <div class="appList">
          <div v-for="item in appList" :key="item.id" class="appItem">
            <img
              :src="item.icon"
              alt=""
              style="
                width: 40px;
                border-radius: 5px;
                overflow: hidden;
                margin-right: 10px;
              "
            />
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- <a-divider type="vertical" :style="{ height: '100' }"></a-divider> -->
      <div class="divider" :style="{}"></div>
      <div class="right">
        <div class="flow">
          <div class="flow-content">
            <div>
              <a-icon
                type="check-circle"
                style="font-size: 20px; color: #a4d3ff; margin-right: 10px"
              />填写信息
            </div>
            <div>{{ record.submitTime }}</div>
          </div>
          <div class="line" style="border-color: #a4d3ff"></div>
          <div class="flow-content">
            <div v-if="type == 'APPROVED'">
              <a-icon
                type="check-circle"
                style="font-size: 20px; color: #a4d3ff; margin-right: 10px"
              />审核通过
            </div>
            <div v-if="type == 'DISAPPROVED'">
              <a-icon
                type="close-circle"
                style="font-size: 20px; color: #ff5151; margin-right: 10px"
              />审核不通过
            </div>
            <div v-if="type == 'WITHDRAW'">
              <a-icon
                type="close-circle"
                style="font-size: 20px; color: #ff5151; margin-right: 10px"
              />已撤销
            </div>
            <div v-if="type == 'UNEXAMINED'">
              <div
                class="icon"
                style="
                  background: #1890ff;
                  color: #fff;
                  border: none;
                  margin-right: 10px;
                "
              >
                <span>2</span>
              </div>
              审核
            </div>
            <div>{{ record.examineTime ? record.examineTime : '-----' }}</div>
          </div>
          <div class="line"></div>

          <div class="flow-content">
            <div>
              <div
                class="icon"
                :style="{
                  marginRight: '10px',
                  background: type === 'APPROVED' ? '#1890ff' : '',
                  border: type === 'APPROVED' ? 'none' : '',
                  color: type === 'APPROVED' ? '#fff' : '',
                }"
              >
                <span>3</span>
              </div>
              上架
            </div>
            <div>
              {{
                record.launchingOperateTime
                  ? record.launchingOperateTime
                  : '-----'
              }}
            </div>
          </div>
        </div>
        <a-form :form="form">
          <a-form-item label="名称">
            <a-input
              :disabled="true"
              :maxLength="30"
              v-decorator="[
                'packageName',
                {
                  rules: [{ required: true }],
                  initialValue: record.packageName,
                },
              ]"
            ></a-input>
          </a-form-item>
          <a-form-item label="描述">
            <a-textarea
              :disabled="true"
              :maxLength="200"
              v-decorator="[
                'packageDescription',
                {
                  rules: [{ required: true }],
                  initialValue: record.packageDescription,
                },
              ]"
            ></a-textarea>
          </a-form-item>
          <a-form-item label="宣传图">
            <!-- <img
              :src="item"
              alt=""
              v-for="item in record.packagePictures"
              :key="item"
              style="width: 25%"
            /> -->
            <a-upload
              :disabled="true"
              list-type="picture-card"
              :file-list="fileList"
              @preview="handlePreview"
              v-decorator="['pictureList', { rules: [{ required: false }] }]"
            >
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancelPicture"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <!-- <div class="left">提交应用</div>
    <a-divider type="vertical"></a-divider>
    <div class="right">右侧</div> -->
  </a-modal>
</template>

<script>
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export default {
  name: 'ApplicationDetail',
  data () {
    return {
      userName: '张三',
      userCompany: '深圳科技公司',
      appList: [
        { name: '项目', img: '' },
        {
          name: '销售活动',
          img: ''
        }
      ],
      form: this.$form.createForm(this),
      appInfo: [],
      fileList: [],
      previewVisible: false,
      previewImage: ''
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'wait'
    },
    record: {
      type: Object,
      default: () => {}
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.fileList = this.record.packagePictures.map((item, index) => {
        return (item = {
          uid: index + 1,
          name: item.split('/').pop(),
          status: 'done',
          url: item
        })
      })
      this.getApplicationInfo(this.record.packageApplicationIds)
    },
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleCancelPicture () {
      this.previewVisible = false
    },
    /* 获取应用保重的应用信息 */
    getApplicationInfo (appIdArr) {
      this.$api.getAppInfo(appIdArr.join(',')).then((res) => {
        // console.log(res, 'app信息')
        this.appList = res
      })
    },

    /* 审核通过 */
    pass () {
      let data = { approved: true, disapproveRemarks: '' }
      this.$api.appPackageExamine(this.record.packageId, data).then((res) => {
        if (res.code === 0) {
          this.$message.success('操作成功')
          this.$emit('close', true)
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    no_pass () {
      let data = { approved: false, disapproveRemarks: '' }
      this.$api.appPackageExamine(this.record.packageId, data).then((res) => {
        if (res.code === 0) {
          this.$message.success('操作成功')
          this.$emit('close', true)
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    handleCancel () {
      this.$emit('close', false)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-body {
  .box {
    display: flex;
    justify-content: space-between;
    .left {
      // width: calc(100% / 2);
      width: 40%;
      padding-right: 20px;
      .left-title {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
      }
    }
    .right {
      // width: calc(100% / 2);
      width: 60%;
      padding-left: 20px;
    }
  }
}

/deep/ .ant-modal-footer {
  div {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
  }
}

.ant-divider {
  height: 100%;
}

.divider {
  width: 1px;
  height: 100%;
  background: #000;
}

.ant-form-item {
  margin-bottom: 0px;
}

.appList {
  background-color: #f3f3f3;
  padding: 20px;
  margin-top: 10px;
  .appItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    margin-bottom: 10px;
  }
}

.footer-operation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .flow-content {
    div {
      margin: 0 auto;
    }
    display: flex;
    flex-direction: column;
    width: 200px;
    :first-child {
      display: flex;
      align-items: center;
    }
  }
}

.icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #e9e9e9;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  position: relative;
  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #e9e9e9;
  }
}

.line {
  width: 40px;
  height: 1px;
  border-top: 1px solid #e9e9e9;
}
</style>